import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../components/layout";
import SEO from "../components/seo";
import JudyGaman from "../images/JudyGaman2.jpg";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

const iconSize = 44;

function Judy() {
    return (
        <Layout>
            <SEO keywords={["Judy Gaman"]} title="Judy" />
            <section>
                <img
                    className="rounded-full mx-auto"
                    alt="Judy Gaman headshot"
                    src={JudyGaman}
                />

                <p className="text-lll-purple-600 my-4">
                    {`A true inspiration, Judy Gaman is an award-winning author, speaker, and radio/podcast host. She’s just as comfortable on a stage, or behind a camera or mic, as she is in a boardroom, or curled up to write a new book. Whatever the topic, she takes her audiences on a journey and always leaves them wanting more. Many people have referred to her as a master storyteller, something that’s allowed her to touch people’s lives and motivate them towards real change. It’s that story-telling gift that brings Lucille Fleming to life in the pages of her new memoir,`}{" "}
                    <span className="italic">{`Love, Life, and Lucille.`}</span>
                </p>
                <p className="text-lll-purple-600 my-4">
                    {`Judy’s a graduate of the prestigious George Washington University School of Medicine and Health Sciences as well as the School of Professional Studies. Currently, she’s completing a graduate certificate from Harvard in Strategic Management. Her experience and education led her to her position as CEO of Executive Medicine of Texas, a luxury medical practice in the Dallas/Fort Worth area, where she spends most of her time.`}
                </p>
                <p className="text-lll-purple-600 my-4">
                    {`For nearly a decade you could find Judy coast-to-coast on the airwaves as a co-host of the nationally syndicated `}{" "}
                    <span className="italic">{`Staying Young Show`}</span>{" "}
                    {` with Drs. Mark Anderson and Walter Gaman of Executive Medicine of Texas. Her infectious personality and love of research kept the audience entertained and educated on health topics. She and the doctors now continue this through the`}{" "}
                    <span className="italic">{`Stay Young America! podcast.`}</span>
                </p>
                <p className="text-lll-purple-600 my-4">
                    {`Judy has crossed genres as a writer with past books including:`}{" "}
                    <span className="italic">{`Age to Perfection: How to Thrive to 100, Happy, Healthy, and Wise`}</span>
                    {", "}
                    <span className="italic">{`Stay Young: 10 Proven Steps to Ultimate Health`}</span>
                    {", "}
                    <span className="italic">{`Executive Medicine: Optimizing Your Chances of a Longer Life`}</span>
                    {", and "}
                    <span className="italic">{`What Not to Build`}</span>
                    {". "}{" "}
                    {`But perhaps, her favorite, most heart-felt book of all is`}{" "}
                    <span className="italic">{`Love, Life, and Lucille`}</span>
                    {`. A tribute to her best friend Lucille, a centenarian who changed her life forever.`}
                </p>
                <p className="text-lll-purple-600 my-4">
                    {`To book Judy as a speaker, a media guest, or for a book signing,
          please complete the`}
                    <OutboundLink
                        id="Link-JudyGaman"
                        className="text-lll-purple-800 font-bold"
                        href="https://www.judygaman.com/"
                    >
                        {` CONTACT US form HERE.`}
                    </OutboundLink>{" "}
                </p>
                <div className="flex flex-row justify-center">
                    <OutboundLink
                        className="mx-4"
                        href="https://www.facebook.com/jgamanspeaker/"
                    >
                        <FacebookIcon size={iconSize} round />
                    </OutboundLink>
                    <OutboundLink
                        className="mx-4"
                        href="https://twitter.com/JudyGaman"
                    >
                        <TwitterIcon size={iconSize} round />
                    </OutboundLink>
                    <OutboundLink
                        className="mx-4"
                        href="https://www.linkedin.com/in/judygaman/"
                    >
                        <LinkedinIcon size={iconSize} round />
                    </OutboundLink>
                </div>
            </section>
        </Layout>
    );
}

export default Judy;
